import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link, Props } from 'docz';
import { CodeText } from '@entur/typography';
import { SkipToContent, VisuallyHidden } from '@entur/a11y';
import PageHeader from '~/components/PageHeader';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
  Entur sitt designsystem er utviklet for å være universelt utformet. Det betyr
  at det skal kunne fungere for alle. Her finner du noen ekstra komponenter for
  å forenkle dette arbeidet i løsningene våre også.
    </PageHeader>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "skiptocontent"
    }}>{`SkipToContent`}</h3>
    <ImportStatement imports="SkipToContent" mdxType="ImportStatement" />
    <Props of={SkipToContent} mdxType="Props" />
    <p><inlineCode parentName="p">{`SkipToContent`}</inlineCode>{` er en snarvei for brukere av tastaturnavigasjon for å forenkle veien til sidens hovedinnhold. Den synes ikke for vanlige brukere, men vil vises med en gang man trykker "tab" for å navigere seg nedover siden.`}</p>
    <p>{`Den plasseres så tidlig som mulig i dokumentet. Du kan sende inn id-en til sidens hovedinnhold via `}<inlineCode parentName="p">{`mainId`}</inlineCode>{`-propen, og en tekst som sier noe slikt som "Gå til hovedinnhold" i teksten.`}</p>
    <p>{`Vi bruker den på denne siden også - test den ut med å trykke "tab"!`}</p>
    <h3 {...{
      "id": "visuallyhidden"
    }}>{`VisuallyHidden`}</h3>
    <ImportStatement imports="VisuallyHidden" mdxType="ImportStatement" />
    <Props of={VisuallyHidden} mdxType="Props" />
    <p><inlineCode parentName="p">{`VisuallyHidden`}</inlineCode>{` brukes for å gi skjermlesere en tekstlig beskrivelse av et visuelt element. Det er den logiske motsetningen til `}<inlineCode parentName="p">{`aria-hidden="true"`}</inlineCode>{`, og brukes ofte for å gi informasjon til skjermlesere der man ikke kan (eller vil) vise denne informasjonen til alle brukere.`}</p>
    <VisuallyHidden mdxType="VisuallyHidden">
  Alle de kule utviklerne bruker <CodeText mdxType="CodeText">VisuallyHidden</CodeText>
  -komponenten, asså.
    </VisuallyHidden>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      